@import '../src/scss/themes/theme-dark.scss';
@import '../src/scss/themes/theme-light.scss';
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;300&display=swap');

html {
	font-size: 62.5%;
	-webkit-font-smoothing: antialiased;
	margin: 0;
	padding: 0;
	
}

.aligner {
	display: flex;
	align-items: center;
	justify-content: center;
}

.title-container {
	height: 50px;
}

.title-styles {
	font-family: 'Raleway', sans-serif;
	font-size: 250%;
}

.header-icon {
	height: 150px;
	width: 150px;
}

header {
	position: relative;
	height: 520px;
	min-height: 450px;
	width: 100%;
	background-size: cover;
	-webkit-background-size: cover;
	text-align: center;
	overflow: hidden;
	background-color: #87CEEB;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	padding-top: 150px;
	padding-bottom: 0;
}


.language-icon {
	font-size: 50px;
	margin-right: 20px;
	cursor: pointer;
}

.project-date {
	font-size: 16px;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	padding: 8px 15px 2px 15px;
	position: relative;
	z-index: -500;
	border-top: 5px solid #696969;
	border-radius: 0 0 5px 5px;
	background-color: #696969;
	color: white;
}

.skills-tile {
	background-color: transparentize(#F8F5F4, 0.95);
	padding: 10px 10px 5px 10px;
	width: 100px;
	margin: 5px 0 5px 0;
	border-radius: 20px !important;
}

.language {
	font-size: 25px;
	background-color: #87CEEB;
	padding-bottom: 10px;
	padding-top: 80px;
}

header h1 {
	font-size: 400%;
	text-align: center;
	font-weight: 600 !important;
	color: #353239;
	font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
	margin: 0 auto 18px auto;
	width: 100%;
}

.slider-image {
	border: 5px solid #87CEEB;
}

.slider-tab {
	background-color: #87CEEB;
	height: 25px;
}

.slider-iconfiy {
	margin-top: 10px;
}

.styles_typicalWrapper__1_Uvh::after {
	cursor: none !important;
	display: none;
}

#about {
	background: #fff;
	overflow: hidden;
}

#about h1 {
	padding-top: 5%;
	font: 18px/24px 'opensans-bold', sans-serif;
	text-transform: uppercase;
	letter-spacing: 3px;
	color: black;
	text-align: center;
	
}

.foto img {
	display: block;
	width: 100%;
	
}

.card {
	border-radius: 20px !important;
	border-color: transparent !important;
  }

.foto div {
	background: #ffffff;
	display: inline-block;
	margin: 0 auto 5% auto;
	padding: 10px 10px 5px;
	text-align: center;
	text-decoration: none;
	-webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
	-moz-box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
	box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
	-webkit-transition: all .20s linear;
	-moz-transition: all .20s linear;
	transition: all .20s linear;
	z-index: 0;
	position: relative;
}

.foto div:after {
	color: #333;
	font-size: 25px;
	content: attr(title);
	position: relative;
	top: 15px;
}

.foto div:hover {
	-webkit-transform: scale(1.01);
	-moz-transform: scale(1.01);
	transform: scale(1.01);
	z-index: 10;
	-webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, .7);
	-moz-box-shadow: 0 10px 20px rgba(0, 0, 0, .7);
	box-shadow: 0 10px 20px rgba(0, 0, 0, .7);
}

.project-title-settings {
	margin-top: 5%;
	font: 18px/24px 'opensans-bold', sans-serif;
	text-transform: uppercase;
	letter-spacing: 3px;
	color: black;
	text-align: center;
}

.polaroid img {
	display: block;
	max-width: 300px;
}

.polaroid span {
	background: #ffffff;
	display: inline-block;
	margin: 55px 75px 30px;
	padding: 15px 15px 30px;
	text-align: center;
	text-decoration: none;
	-webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
	-moz-box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
	box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
	-webkit-transition: all .20s linear;
	-moz-transition: all .20s linear;
	transition: all .20s linear;
	z-index: 0;
	position: relative;
}

.link-href {
	color: black;
}

.wave {
	font-size: 160%;
}

.font-trebuchet {
	font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

#preview {
	width: 500px;
	background-color: #ebeaf5;
	padding: 15px;
	position: relative;
	margin-bottom: 15%;
	
}

.vertical-timeline-element-content {
	border-radius: 20px !important;
  }

.center {
	display: flex;
	justify-content: center;
	align-items: center;
}

#resume {
	background: #87CEEB;
}

.experience-icon {
	font-size: 300%;
	margin-top: 25%;
	text-align: center;

}

.main-badge {
	font-size: 13px !important;
	text-align: left !important;
	padding: 5px 8px 5px 8px !important;
	vertical-align: baseline;
	background-color: #219ebc !important;
	color: #ffffff;
	font-weight: lighter !important;
	font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
	border-radius:  20px !important;
}

.experience-badge {
	font-size: 11px !important;
	text-align: left !important;
	padding: 5px 8px 5px 8px !important;
	vertical-align: baseline;
	background-color: #90e0ef !important;
	color: #000000 !important;
	font-weight: lighter !important;
	font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
	margin-right: 5px;
	border-radius: 20px !important;
}

.vertical-timeline-element-date {
	padding: 0 !important;
	margin: 0 !important;
	
}

@media only screen and (max-width: 1170px) {
	.experience-icon {
		font-size: 170%;
		margin-top: 27%;
		text-align: center;
	}
}

.modal-inside .modal-content {
	background: white;
}

.bars {
	width: 95%;
	float: left;
	padding: 0;
	text-align: left;
}

.bars .skills {
	margin-top: 36px;
	list-style: none;
}

.bars li {
	position: relative;
	margin-bottom: 60px;
	background: #ccc;
	height: 42px;
	border-radius: 4px;
}

.bars li em {
	font: 15px 'opensans-bold', sans-serif;
	color: #313131;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-weight: normal;
	position: relative;
	top: -36px;
}

.bar-expand {
	position: absolute;
	left: 0;
	top: 0;
	margin: 0;
	padding-right: 24px;
	background: #313131;
	display: inline-block;
	height: 42px;
	line-height: 42px;
	border-radius: 3px 0 0 3px;
}

.modal-close {
	text-align: right;
	padding: 10px 15px 10px 15px;
	cursor: pointer;
}

.close-icon {
	color: black;
	font-weight: lighter !important;
}

.modal-description {
	text-align: justify;
	padding: 5px 5px 0 5px;
	margin-bottom: 20px;
	font-size: 12px;
}

.awssld__next {
	outline: none !important;
}

.awssld__prev {
	outline: none !important;
}

.loader-bar-color {
	color: black !important;
}

#portfolio .fa-github {
	margin-top: 20px;
	transition: transform 0.2s;
  }
  
  #portfolio .fa-github:hover {
	transform: scale(1.1);
	color: #333; // promijeni boju na hover
  }
  
  @keyframes beat {
	0%, 100% {
	  transform: scale(1);
	}
	50% {
	  transform: scale(1.1);
	}
  }
  
  .beat-icon {
	animation: beat 2s infinite; // Trajanje animacije 2 sekunde
  }
  

#portfolio {
	background: #87CEEB;
	padding-bottom: 5%;
}

.portfolio-item {
	max-width: 100%;
	margin-bottom: 15px;
	text-align: center;
	justify-content: center;
}

.portfolio .portfolio-item .portfolio-item-caption {
	-webkit-transition: all ease 0.5s;
	-moz-transition: all ease 0.5s;
	transition: all ease 0.5s;
	opacity: 0;
	background-color: rgba(51, 51, 51, 0.9);
}

.portfolio .portfolio-item .portfolio-item-caption:hover {
	opacity: 1;
}

.portfolio .portfolio-item .portfolio-item-caption .portfolio-item-caption-content {
	font-size: 1.5rem;
}

@media (min-width: 576px) {
	.portfolio .closeButtonResponsive {
		display: block;
	}
	.portfolio .portfolio-item {
		margin-bottom: 30px;
	}
}

#skills {
	background: #1F1F1F;
	min-height: 200px;
	width: 100%;
	overflow: hidden;
	padding-bottom: 10%;
}

.section-title {
	padding-top: 5%;
	padding-bottom: 5%;
	font: 18px/24px 'opensans-bold', sans-serif;
	text-transform: uppercase;
	letter-spacing: 3px;
	color: #fff;
	text-align: center;
}

.skill-icon {
	font-size: 180%;
	color: white;
	text-align: center;
	position: relative;
}

footer {
	background: #1F1F1F;
	min-height: 100px;
	width: 100%;
	overflow: hidden;
	font-size: 14px;
	color: white;
	position: relative;
	text-align: center;
}

footer a,
footer a:visited {
	color: #fff;
}

footer a:hover,
footer a:focus {
	color: #fff;
}

.social-links {
	margin-top: 50px;
	font-size: 22px;
}

@keyframes slideInLeft {
	from {
	  opacity: 0;
	  transform: translateX(-100px); /* Dolazi s lijeve strane */
	}
	to {
	  opacity: 1;
	  transform: translateX(0); /* Dolazi na svoje mjesto */
	}
  }
  
  .slide-in-left {
	opacity: 0; /* Element je nevidljiv dok ne dođe u vidno polje */
	transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  }
  
  .slide-in-left.visible {
	animation: slideInLeft 1s forwards;
  }
  


  @keyframes popInLeft {
	0% {
	  opacity: 0;
	  transform: translateX(-100px) scale(0.8); /* Počinje van vidnog polja i manja */
	}
	60% {
	  opacity: 1;
	  transform: translateX(10px) scale(1.1); /* Malo prelazi poziciju i povećava se */
	}
	100% {
	  opacity: 1;
	  transform: translateX(0) scale(1); /* Dolazi na svoje mjesto */
	}
  }
  
  .pop-in-left {
	opacity: 0; /* Element je nevidljiv dok ne dođe u vidno polje */
	transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  }
  
  .pop-in-left.visible {
	animation: popInLeft 0.8s forwards; /* Trajanje i pokretanje animacije */
  }
  



  #particles-js {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	mask-image: radial-gradient(circle at center, transparent 200px, black 300px);
}

#particles-projects {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	mask-image: radial-gradient(circle at center, transparent 200px, black 300px);



@media (max-width: 576px) {
	display: block; /* Omogućava prikaz na mobilnim uređajima */
}

}


:root {
	--particles-color-light: #000000;
	--particles-color-dark: #ffffff;

	--particles-two-color-light: #000000;
	--particles-two-color-dark: #ffffff !important;

	--icon-hover-color-light: rgb(33, 158, 188); /* Boja za light mode */
	--icon-hover-color-dark: #919191; /* Boja za dark mode */
}

[data-theme="light"] {
	--particles-color: var(--particles-color-light);
	--particles-two-color: var(--particles-two-color-light);

	--icon-hover-color: var(--icon-hover-color-light);
}

[data-theme="dark"] {
	--particles-color: var(--particles-color-dark);
	--particles-two-color: var(--particles-two-color-dark);

	--icon-hover-color: var(--icon-hover-color-dark);
}



  @keyframes pulse {
	0% {
	  transform: scale(1);
	}
	50% {
	  transform: scale(1.1);
	}
	100% {
	  transform: scale(1);
	}
  }
  
  @keyframes swing {
	0% {
	  transform: rotate(0deg);
	}
	50% {
	  transform: rotate(10deg);
	}
	100% {
	  transform: rotate(0deg);
	}
  }
  
  .social-links span {
	display: inline-block;
	animation: pulse 2s infinite; /* Pulsirajući efekt */
	transition: transform 0.3s ease, color 0.3s ease; /* Glatki prijelaz */
  }
  
  .social-links span a {
	color: white; /* Početna boja ikona */
  }
  
  .social-links span:hover {
	animation: swing 0.5s ease; /* Ljuljanje na hover */
	transform: scale(1.2); /* Blago povećanje prilikom hovera */
  }
  
  .social-links span:hover a {
	color: var(--icon-hover-color); /* Boja po izboru za hover */
  }
  

  @keyframes rotate {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }
  
  @keyframes sway {
	0%, 100% {
	  transform: translateX(0);
	}
	50% {
	  transform: translateX(4px);
	}
  }
  
  /* Pješčani sat - polagana rotacija koja se ponovi nekoliko puta */
  .experience-icon.fa-hourglass-start {
	animation: rotate 8s linear 10; /* 8 sekundi po rotaciji, 3 puta */
  }
  
  /* Polagano pomicanje lijevo-desno za programiranje ikone */
  .experience-icon.fa-code {
	animation: sway 4s ease-in-out infinite;
  }
  

  .skill-icon {
	transition: transform 0.3s ease, color 0.3s ease;
	color: white; /* Početna boja ikone */
  }
  
  .skill-icon:hover {
	color: var(--icon-hover-color);  /* Boja na hover */
	
  }
  

  .typewriter-text {
	display: inline-block;
	white-space: pre-wrap; /* Omogućava višeredno prikazivanje */
	border-right: 2px solid black; /* Kursor efekt */
	animation: blinkCursor 0.75s step-end infinite; /* Trepćući kursor */
  }
  
  @keyframes blinkCursor {
	50% {
	  border-color: transparent;
	}
  }
  

  /* Animacija za mahanje rukom */
.wave-emoji {
	display: inline-block;
	animation: wave 1s infinite alternate;
  }
  
  @keyframes wave {
	0% {
	  transform: rotate(0deg);
	}
	50% {
	  transform: rotate(15deg);
	}
	100% {
	  transform: rotate(-15deg);
	}
  }
  
  /* Animacija za lansiranje rakete */
  .rocket-emoji {
	display: inline-block;
	position: relative;
	animation: launch 2s infinite;
  }
  
  @keyframes launch {
	0% {
	  transform: translateY(0);
	}
	30% {
	  transform: translateY(-10px);
	}
	60% {
	  transform: translateY(-20px);
	}
	100% {
	  transform: translateY(-30px);
	}
  }
  

  /* Animacija za ljuljanje smajlića */
.smile-emoji {
	display: inline-block;
	animation: sway 1.5s ease-in-out infinite;
  }
  
  @keyframes sway {
	0%, 100% {
	  transform: rotate(0deg);
	}
	50% {
	  transform: rotate(10deg);
	}
  }
  
/* Animacija za kucanje srca */
.beat-emoji {
	display: inline-block;
	animation: beat 1.7s infinite;
}

@keyframes beat {
	0%, 100% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.3);
	}
}




  :root {
	--animated-name-color-light: rgb(33, 158, 188); /* Plava boja za light mode */
	--animated-name-color-dark: #ffffff; /* Bijela boja za dark mode */
  }
  
  [data-theme="light"] {
	--animated-name-color: var(--animated-name-color-light);
  }
  
  [data-theme="dark"] {
	--animated-name-color: var(--animated-name-color-dark);
  }
  
  .animated-name {
	position: relative;
	font-size: 5.5rem;
  
	.first {
	  color: transparent;
	  -webkit-text-stroke: 1px rgb(31, 31, 31);
	  position: absolute;
	}
  
	.second {
	  color: var(--animated-name-color);
	  -webkit-text-stroke: 0.5px rgb(31, 31, 31); /* Manje debljine na manjim ekranima */
	  animation: waves 4s ease-in-out infinite;
	}
  
	@media (max-width: 768px) {
		//font-size: 3rem;
	
		.first, .second {
			-webkit-text-stroke: 0.2px rgb(31, 31, 31); /* Još manja debljina obruba */
			text-shadow: 0 0 0 rgb(31, 31, 31); /* Poboljšava čitljivost na manjim ekranima */
		  }
		}

		@media (max-width: 480px) {
			font-size: 4rem; // Još manje za vrlo male ekrane
		
			.first,
			.second {
			  -webkit-text-stroke: 0.2px rgb(31, 31, 31); // Još tanji obrub
			  text-shadow: 0 0 0 rgb(31, 31, 31); // Poboljšava čitljivost na malim ekranima
			}
		  }
	} 
  
  @keyframes waves {
	0%,
	100% {
	  clip-path: polygon(
		0% 45%,
		16% 44%,
		33% 50%,
		54% 60%,
		70% 61%,
		84% 59%,
		100% 52%,
		100% 100%,
		0% 100%
	  );
	}
  
	50% {
	  clip-path: polygon(
		0% 60%,
		15% 65%,
		34% 66%,
		51% 62%,
		67% 50%,
		84% 45%,
		100% 46%,
		100% 100%,
		0% 100%
	  );
	}
  }
  

  :root {
	--gradient-light: linear-gradient(60deg, rgb(33, 158, 188), #219ebc, #87CEEB, #56CFE1, #4EA8DE, #219ebc, #87CEEB, rgb(33, 158, 188));
	--gradient-dark: linear-gradient(60deg, #1F1F1F, #5b5b5b, #919191, black, #494949);
  }
  
  [data-theme="light"] {
	--gradient-border: var(--gradient-light);
  }
  
  [data-theme="dark"] {
	--gradient-border: var(--gradient-dark);
  }
  
  .new-card {
	--borderWidth: 6px;
	position: relative;
	padding: 20px;
	border-radius: 20px;
	background-color: #fff;
	overflow: hidden;
  
	&::after {
	  content: "";
	  position: absolute;
	  top: 0;
	  left: 0;
	  right: 0;
	  bottom: 0;
	  padding: 5px;
	  border-radius: 20px;
	  background: var(--gradient-border); /* Koristi varijablu za gradient */
	  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
	  mask-composite: exclude;
	  -webkit-mask-composite: destination-out;
	  background-size: 300% 300%;
	  animation: animatedgradient 3s ease alternate infinite;
	  z-index: 1;
	}
  
	.card-body {
	  position: relative;
	  z-index: 2;
	}
  }
  
  @keyframes animatedgradient {
	0% {
	  background-position: 0% 50%;
	}
	50% {
	  background-position: 100% 50%;
	}
	100% {
	  background-position: 0% 50%;
	}
  }
  