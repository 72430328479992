body[data-theme="light"] {
    
    @import '../light-slider.scss';

    
 
    .slider-tab {
        background-color: #87CEEB; 
     } 
    
    
} 